/* HomePage.css */

.homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f0f0f0; /* Set the background color as needed */
}

.button-login {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: rgb(52, 52, 241);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-top: 30px;
  width: 150px;;
  height: 45px;
}

.homepage-content {
  text-align: center;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  margin-bottom: 20px;
}

.homepage-image {
  max-width: 60%;
  height: auto;
  margin-bottom: 20px;
}

.video-container {
  display: flex;
  justify-content: center;
  margin-left: 20%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio for video */
  width: 60%;
  border: none;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
