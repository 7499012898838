.chatbot-card {
  border-radius: 20px;
  min-height: 200px;
  cursor: pointer;
  width: 300px !important;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  &:hover {
    box-shadow: rgba(46, 142, 252, 0.867) 0px 0px 0px 3px;
  }
  .switch-btn {
    .ant-switch-inner-unchecked {
      background-color: #9c9fa4 !important;
    }
  }
}
.chatbot-card-add {
  border-radius: 20px;
  width: 300px !important;
  cursor: pointer;
  height: 200px;
  background-color: #0156f9;
}
.chatbot-add-model {
  .ant-modal-content {
    border-radius: 30px;
  }
  ::-webkit-scrollbar-track-piece {
    background: transparent;
  }
}
.segmented-button-container {
  .ant-segmented {
    border-radius: 15px;
  }
  .ant-segmented-item-selected {
    background-color: #076ec3 !important;
    color: white !important;
    border-radius: 15px;
  }
  .ant-segmented-thumb {
    background-color: #076ec3 !important;
    color: white !important;
    border-radius: 15px;
  }
}
.chatbot-user-guide{
    a{
        color: #0156f9!important;
    }
}
