.App {
  font-family: "Roboto", sans-serif;
  .language {
    position: absolute;
    right: 20px;
    top: 10px;

    button {
      font-size: 11px;
      letter-spacing: 1px;
      width: 160px;
    }

    .language-list {
      background-color: #202123;
      margin-top: 5px;
      border-radius: 10px;
      padding: 5px;
      .langs {
        z-index: 9;
        padding: 3px 5px;
        color: #ffffff;
        font-size: 11px;
        letter-spacing: 1px;
        width: 100%;
        cursor: pointer;

        &:hover {
          background-color: #3a3b3c;
        }
      }
    }
  }
  .ant-modal-content {
    background-color: #343541 !important;
  }
  .customer_support {
    display: flex;
    justify-content: center;
    .h-text {
      transition: all 0.5s ease-in-out;
      color: #ffffff;
      margin-bottom: 2.5rem;
      position: absolute;
      text-align: center;
    }
  }
  .card-container {
    max-width: 150px;
    border: 0;
    margin: 5px;
    border-radius: 20px;
    width: 100%;
    background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);

    margin-inline: auto;
    .container-card {
      position: relative;
      height: 100%;

      border: 2px solid transparent;
      background: linear-gradient(71deg, #080509, #1a171c, #080509);
      background-clip: padding-box;
      border-radius: 20px;
      padding: 5px 20px;
    }
    .card-title {
      font-weight: 600;
      color: white;
      font-style: normal;
      font-size: 18px;
    }
    .card-description {
      font-weight: 500;
      line-height: 10px;
      color: hsla(0, 0%, 100%, 0.5);
      font-size: 12px;
    }

    .bg-green-box {
      position: relative;
    }

    .bg-green-box::after {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      content: "";
      z-index: -1;
      background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
      border-radius: 45px;
    }
  }

  .translate {
    position: absolute;
    right: 200px;
    top: 10px;

    button {
      font-size: 11px;
      letter-spacing: 1px;
      width: 160px;
    }

    .language-list {
      background-color: #202123;
      margin-top: 5px;
      border-radius: 10px;
      padding: 5px;
      .langs {
        z-index: 9;
        padding: 3px 5px;
        color: #ffffff;
        font-size: 11px;
        letter-spacing: 1px;
        width: 100%;
        cursor: pointer;

        &:hover {
          background-color: #3a3b3c;
        }
      }
    }
  }

  .arrow {
    position: absolute;
    color: white;
    top: 3%;
    left: 280px;
    transition: all 0.5s ease-in-out;
    z-index: 4;
    cursor: pointer;

    &.rotate {
      transform: rotate(180deg);
      left: 1%;
    }
  }
  .left-nav {
    z-index: 3;
    position: absolute;
    width: 270px;
    height: 100vh;
    background-color: #202123;
    transition: all 0.5s ease-in-out;
    .upper-section {
      form {
        input {
          background: transparent;
          color: white;
          border: 1px solid #797e88;
          width: 90%;
          margin: 0 auto;
          &::placeholder {
            color: #797e88;
          }
        }
      }
      .dropdown {
        width: 90%;
        background-color: black;
        border: none;
        color: #797e88;
        margin: 0 auto;
      }
      .Dropdown-menu {
        margin-top: 5px;
        background-color: #202123;
        border: none;

        .Dropdown-option {
          color: #ffffff;
          &.is-selected {
            background-color: #34383b;
          }
          &:hover {
            background-color: #34383b;
          }
        }
      }
    }
    .lower-section {
      ul {
        border-top: 1px solid #3d3f42;

        li,
        .li {
          height: 40px;
          cursor: pointer;
          background-color: transparent;
          transition: all 0.1s ease-in-out;
          font-size: 14px;
          > * {
            color: #ffffff;
          }
          &:hover {
            background-color: #3a3b3c;
          }
          form {
            input {
              background-color: transparent;
              border: none;
              border-bottom: 1px solid rgba(255, 255, 255, 0.521);
              border-radius: 0;
              color: #ffffff;
              height: 20px;

              &::placeholder {
                color: #797e88;
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
    &.showNav {
      left: 0;
    }
    &.hideNav {
      left: -400px;
    }
  }
  .center-nav {
    width: 100vw;
    height: 100vh;
    background-color: #343541;
    transition: all 0.5s ease-in-out;

    .loader {
      transition: all 0.5s ease-in-out;
      position: absolute;
      top: 1.5%;
      left: 55%;
      z-index: 9;

      &.active {
        left: 46%;
      }
    }

    .home-page {
      transition: all 0.5s ease-in-out;
      position: absolute;
      width: 60%;

      left: 30%;
      top: 25%;
      h2 {
        color: #ffffff;
      }
      .lead {
        color: white;
      }
      h5 {
        color: #ffffff;
      }
      .text-small {
        color: #ffffff56;
        font-size: 14px;
      }
      &.active {
        left: 22%;
      }
    }
    .chatbot-ui {
      transition: all 0.5s ease-in-out;
      position: absolute;
      top: 5%;
      height: 82.8vh;
      width: 100%;
      overflow-y: auto;

      h2 {
        transition: all 0.5s ease-in-out;
        color: #ffffff;
        margin-bottom: 2.5rem;
        position: absolute;
        left: 49.8%;
      }
      h6 {
        transition: all 0.5s ease-in-out;
        color: #ffffff;
        margin-bottom: 2.5rem;
        position: absolute;
        left: 55.5%;
      }

      .response {
        width: 100%;
        margin: 0 auto;
        height: auto;
        overflow-y: auto;
        span {
          font-size: 1.2rem;
        }
        p {
          letter-spacing: 1px;
          margin-top: 9px;
          font-weight: 300;
          white-space: pre-wrap;
          color: #ccd0da;
          font-size: 1rem;
          > * {
            font-size: 1rem !important;
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }

        .input {
          .user-query {
            padding-left: 30%;
            padding-right: 100px;
          }
        }
        .response-text {
          transition: all 0.5s ease-in-out;
          padding-left: 29.9%;
          background-color: #444654;
          padding-right: 200px;

          .copy {
            position: absolute;
            right: 80px;
            margin-top: 12px;
            font-size: 15px;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }
          .speaker {
            position: absolute;
            right: 95px;
            margin-top: 7px;
          }
          .heart {
            position: absolute;
            right: 50px;
            margin-top: 7px;
            cursor: pointer;
            color: white;
          }
          .share {
            position: absolute;
            right: 25px;
            margin-top: 7px;
            cursor: pointer;
            color: white;
          }
        }
      }
      .search-bar {
        background-color: #444654;
        position: fixed;
        width: 58.6%;
        bottom: 31px;
        left: 29%;
        transition: all 0.5s ease-in-out;

        input {
          background-color: #40414f;
          color: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.096);
          padding: 11px 20px;

          &::placeholder {
            color: #ffffff56;
          }
          &:focus {
            border: 1px solid white;
          }
        }
        &.active {
          left: 20%;
          width: 68%;
        }
        .microphone {
          position: absolute;
          right: 14px;
          top: 5px;
          font-size: 20px;
          cursor: pointer;
          transition: all 0.5s ease-in-out;

          &:hover {
            transform: scale(1.2);
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
      &.active {
        h2 {
          left: 42.8%;
          font-size: 20px;
          letter-spacing: 1px;
        }
        h6 {
          left: 45.5%;
          letter-spacing: 1px;
        }
        p {
          font-size: 15px;
          letter-spacing: 1px;
        }
        .response {
          .input {
            .user-query {
              padding-left: 20%;
            }
          }
          .response-text {
            padding-left: 19.9%;
          }
        }
      }
    }

    .user-query {
      transition: all 0.5s ease-in-out;
      font-weight: 300;
      background-color: #343541;

      &:first-child {
        font-size: 2rem;
        color: #ffffff;
      }
      .response-input {
        color: #797e88;
        font-size: 1.2rem;
      }
    }
  }

  .type-writter {
    .blinking-slash {
      animation: blink 1s infinite;
      font-size: 1rem;
    }
    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  .settings,
  .license,
  .create-license,
  .Supervisor,
  .view-items,
  .customer_support,
  .item-detail-view,
  .message-detail-view,
  .stocks-container,
  .Room_History,
  .currencies-container,
  .airbnb-container,
  .air-bnb-properties-container,
  .jobs-container,
  .job-search-container,
  .global-retailer-container,
  .global-retailer-form-container,
  .ai_input_container,
  .ai_image_response_container,
  .three_sixty_view,
  .stock-detail-container,
  .ether-connect-container {
    color: white;
    width: 100vw;
    height: 100vh;
    margin: auto;
    background-color: #292935;
    padding: 30px 50px;
    .supervisor-form {
      width: 400px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .form-control {
        background-color: #12121a;
        color: #e0d6d6;
        font-size: 15px;
        letter-spacing: 1px;

        &::placeholder {
          color: rgb(102, 89, 89);
          font-size: 10px;
          letter-spacing: 1px;
        }

        &:focus {
          box-shadow: none;
        }
      }
      .btn {
        font-size: 13px;
        letter-spacing: 1px;
      }
    }
    .settings-wrapper,
    .license-wrapper {
      transition: all 0.5s ease-in-out;
      position: relative;
      left: 138px;
      width: 70%;
      height: 100%;
      margin: 0 auto;
      background-color: #12121a;
      border-radius: 20px;
      padding: 20px;

      .header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        font-size: 0.8rem;
        letter-spacing: 2px;
        color: white;

        .back-icon {
          width: 1.2rem;
          color: white;
        }
      }
      .search-bar {
        input {
          width: 50%;
          margin: 2rem auto;
          background-color: #12121a;
          color: wheat;
          border-radius: 0;
          border: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.39);

          &::placeholder {
            color: rgba(255, 255, 255, 0.596);
          }
          &:focus {
            outline: none;
            box-shadow: none !important;
          }
        }
      }
      .api-keys-wrapper,
      .license-form,
      .create-license-form {
        border: none;
        border-top: 1px solid rgba(255, 255, 255, 0.075);
        border-radius: 0;
        padding: 20px 10px;
        height: 60vh;
        overflow-y: auto;

        .dragAndDrop {
          text-align: center;
          color: white;
          border-radius: 20px;
          border: 1px dashed #413c3c5b;
        }

        .drag-active {
          border: 1px dashed #ffffff;
        }
        p {
          font-size: 10px;
          letter-spacing: 1px;
          padding: 2rem 0;
        }

        .api-keys-header {
          width: 100%;
          margin: 0 auto;

          p {
            color: white;
            letter-spacing: 2px;
            font-size: 0.7rem;
            text-align: center;
          }
        }
        .api-keys {
          li {
            color: white;
            font-size: 0.7rem;
            letter-spacing: 2px;

            span {
              width: 14%;
            }

            input {
              width: 70%;
              background: none;
              border: none;
              border-radius: 0;
              color: rgba(255, 255, 255, 0.582);
              letter-spacing: 2px;
              font-size: 0.7rem;
              border-bottom: 1px solid rgba(255, 255, 255, 0.411);
              &::placeholder {
                color: rgba(255, 255, 255, 0.596);
                font-size: 0.6rem;
              }
              &:focus {
                outline: none;
                box-shadow: none !important;
              }
            }
            .eye-icon,
            .check-icon {
              width: 1rem;
            }
          }
        }
        .form {
          input {
            background-color: transparent;
            color: white;
            font-size: 10px;
            letter-spacing: 1px;
            border: 1px solid rgba(128, 128, 128, 0.651);

            &::placeholder {
              color: rgba(255, 255, 255, 0.589);
              font-size: 9px;
              letter-spacing: 1px;
            }
            &:focus {
              outline: none;
              box-shadow: none !important;
            }
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &.active {
        left: 0;
      }
    }
    .share-icon:hover {
      color: #076ec3;
    }

    .room_header {
      position: fixed;
      width: 100%;
      top: 30px;
      left: 0;
      font-size: 20px;
      letter-spacing: 1px;
      color: #ffffff;
      text-align: center;
      line-height: 4rem;
    }

    .room_history_wrapper {
      width: 80%;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .room_history_container {
        padding: 10px;
        height: auto;
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;

        > * {
          padding-bottom: 2rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.075);

          &:last-child {
            border-bottom: none;
          }
        }

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgb(126, 119, 119);
        }
      }
    }
  }
  .room {
    width: 100vw;
    height: 100vh;
    // background-color: #343541;

    .form {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .header {
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 11px;
        margin-bottom: 3rem;
      }

      .form-control {
        width: 400px;
        background-color: #343541;
        color: white;
        font-size: 12px;
        padding: 8px 9px;
        &::placeholder {
          color: rgba(255, 255, 255, 0.548);
          font-size: 10px;
          letter-spacing: 1px;
        }
      }
      .btn {
        width: 400px;
      }
    }
  }
  .history {
    .user_input {
      color: rgb(255, 255, 255);
      text-transform: capitalize;
      font-size: 1rem;
      letter-spacing: 1px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    }
    .user_response {
      font-size: 0.8rem;
      color: rgba(255, 255, 255, 0.541);
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    }
    .user_time {
      font-size: 0.7rem;
      color: silver;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    }
  }
  .three_sixty_view {
    .three_sixty_input_div {
      background-color: hsl(0deg 2% 8% / 14%);
    }
    .ant-select-arrow{
      color: white;
    }
  }
}
